import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  // styleUrls: ['./error-page.component.css'],
})
export class ErrorPageComponent implements OnInit {
  public state = '';

  constructor(public activatedRoute: ActivatedRoute) { }
  ngOnInit(): void {
    this.activatedRoute.paramMap
      .pipe(map(() => window.history.state));
  }

}
