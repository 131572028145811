import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CanDeactivate, NavigationEnd, Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';

export class JsonFormControlOptions {
  min?: any;
  max?: any;
  step?: string;
  icon?: string;
}
export class JsonFormControls {
  name?: string;
  label?: string;
  value?: any;
  type?: string;
  options?: JsonFormControlOptions;
  disabled?: boolean;
  validators: Array<ValidatorFn> = new Array<ValidatorFn>();
  validation?: any;
  required?: boolean;
  bindings?: any;
}
export interface JsonFormData {
  controls: JsonFormControls[];
}
@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush,

})
export class FormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() data: any;
  @Input() title: any;

  @Input() editMode: Observable<boolean> = new Subject<boolean>();

  @Input() saveAction: Observable<boolean> = new Subject<boolean>();
  @Input() appendData: any;

  // sets a readonly state
  @Input() readOnly = false;

  private edit = false;

  public myForm: FormGroup = this.fb.group({});

  public routerSub: Subscription;
  public subEdit$: Subscription;
  public saveAction$: Subscription;
  public myForm$: Subscription;

  @Output('disableDeactivate') disableDeactivate = new EventEmitter<boolean>();

  @Output('outputData') outputData = new EventEmitter<any>();

  // @Input() subFormEmit: FormGroup = new FormGroup({})
  private subFormValid = false;
  @Output('subform') subForm = new EventEmitter<FormGroup>();
  @Output('allValid') allValid = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder)  {

  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log('ONCHANGE TRIGGERD');
    this.edit = false;
    this.ngOnDestroy();
    this.myForm = this.fb.group({});
    this.ngOnInit();
  }
  ngOnDestroy(): void {
    if (!!this.subEdit$) {
      this.subEdit$.unsubscribe();
    }
    if (!!this.saveAction$) {
      this.saveAction$.unsubscribe();
    }
    if (!!this.myForm$) {
      this.myForm$.unsubscribe();
    }
  }

  get isDisabled(): boolean {
    return this.edit;
  }

  codeEditorReady(e: any) {
    console.debug("CODE EDITOR READY", e)
    this.editMode
  }

  subFormValidHandler(any: FormGroup) {
    // console.log('VALID HANDLER', any);
    this.subFormValid = any.valid;
    this.allValid.emit(this.subFormValid && this.myForm.valid);
  }

  appendIndend(any: any) {
    this.outputData.emit(any);
  }

  parse(): JsonFormControls[] {
    const list: JsonFormControls[] = new Array<JsonFormControls>();

    this.data?.forEach((element: any) => {
      const x = new JsonFormControls();
      if (element.name === typeof String) {
        element.name = String(element.name).replace('.', '_');
      }
      x.value = element.value;
      x.name =  element.name;
      x.type = element.type;
      x.options = element.options;
      x.disabled = element.readOnly || !this.edit;
      x.required = element.required || false;
      x.bindings = element.binding;
      x.validation = element.validation;
      if (Array.isArray(element.validation)) {
        element.validation?.forEach((val: any) => {
          switch (val.type) {
            case 'min':
              x.validators.push(Validators.min(val.min));
              break;
            case 'max':
              x.validators.push(Validators.max(val.max));
              break;
            case 'required':
              x.validators.push(Validators.required);
              break;
            case 'requiredTrue':
              x.validators.push(Validators.requiredTrue);
              break;
            case 'email':
              x.validators.push(Validators.email);
              break;
            case 'minLength':
              x.validators.push(Validators.minLength(val.minLength));
              break;
            case 'maxLength':
              x.validators.push(Validators.maxLength(val.maxLength));
              break;
            case 'pattern':
              x.validators.push(Validators.min(val.pattern));
              break;
            case 'regex':
              x.validators.push(Validators.pattern(val.regex));
              break;
            case 'nullValidator':
              x.validators.push(Validators.nullValidator);
              break;
            default:
              break;
          }
        });
      }

      list.push(x);
    });

    return list;
  }

  ngOnInit(): void {
    this.init();
    this.data.forEach(el => {
      console.log(el.name);
    });
  }

  init(): void {
    // this.data.forEach(el => {
    //   el.name = String(el.name).replace('.', '');
    // });

    this.createForm(this.parse());
    this.subEdit$ = this.editMode.subscribe((arg) => {
      this.edit = arg;
      this.editControls();
    });

    // saveActions
    this.myForm$ = this.saveAction.subscribe((arg) => {
      const vals = Object.entries(this.myForm.getRawValue());
      const allowed: Map<string, any> = new Map<string, any>();
      this.data.forEach((element: any) => {
        vals.forEach((key: any, val: any) => {
          if (key[0] === element.name) {
            if (element['ignore-on-save'] === false) {
              allowed.set(key[0], key[1]);
            }
          }
        });
      });

      const withIgnored = Object.fromEntries(allowed);
      if (arg) {
        this.outputData.emit(withIgnored);
      }
    });

    // value changes for validatiors
    this.myForm$ = this.myForm.valueChanges.subscribe((data) => {
      this.allValid.emit(this.myForm.valid);
      this.subForm.emit(this.myForm);
      console.log(this.myForm.controls.parentUUID);
    });
  }

  onSubEmit(event: any) {
    this.disableDeactivate.emit(true);
  }

  logicPlan(a: any): void {}

  editControls() {
    this.data.forEach((element: any) => {
      if (element.readOnly !== true) {
        if (this.edit) {
          this.myForm?.controls[element.name]?.enable();
        } else {
          this.myForm?.controls[element.name]?.disable();
        }
      }
    });
  }

  createForm(controls: JsonFormControls[]) {
    this.myForm = this.fb.group({});
    for (const control of controls) {
      switch (control.type) {
        case 'date':
          this.myForm.addControl(
            control.name || '',
            this.fb.control(
              { value: new Date(control.value), disabled: control.disabled },
              Validators.compose([...control.validators])
            )
          );
          break;
        case 'datetime':

          let date = new Date();
          if (control.value === null || control.value === undefined) {
            date = new Date();
          } else {
            date = new Date(control.value);
          }

          this.myForm.addControl(
            control.name || '',
            this.fb.control(
              { value: date, disabled: control.disabled },
              Validators.compose([...control.validators])
            )
          );
          break;
        case 'time':
          this.myForm.addControl(
            control.name || '',
            this.fb.control(
              { value: new Date(control.value), disabled: control.disabled },
              Validators.compose([...control.validators])
            )
          );
          break;
        case 'slider':
          this.myForm.addControl(
            control.name || '',
            this.fb.control(
              { value: control.value, disabled: control.disabled },
              Validators.compose([...control.validators])
            )
          );
          break;
        case 'text':
          this.myForm.addControl(
            control.name || '',
            this.fb.control(
              { value: control.value || '', disabled: control.disabled },
              Validators.compose([...control.validators])
            )
          );
          break;
        case 'textarea':
          this.myForm.addControl(
            control.name || '',
            this.fb.control(
              { value: control.value || '', disabled: control.disabled },
              Validators.compose([...control.validators])
            )
          );
          break;
        case 'logicplan-table':
          this.myForm.addControl(
            control.name || '',
            this.fb.control(
              { value: control.value || '', disabled: true },
              Validators.compose([...control.validators])
            )
          );
          break;

        case 'identity':
          this.myForm.addControl(
            control.name || '',
            this.fb.control(
              { value: control.value, disabled: control.disabled }, null          )
          );
          break;
        case 'inline-table':
          console.log('ADDED inline table to controls', control.value);
          this.myForm.addControl(
            control.name || '',
            this.fb.control(
              { value: control.value, disabled: control.disabled }, null          )
          );
          break;
        case 'code_editor':
          console.log('Added Code Editor to form', control.value);
          this.myForm.addControl(
            control.name || '',
            this.fb.control(
              { value: control.value, disabled: control.disabled }, null          )
          );
          break;
        // case 'assetselector':
          // this.myForm.addControl(
          //   control.name || '',
          //   this.fb.control(
          //     { value: control.value, disabled: control.disabled }, Validators.required)

          // );
          // console.log("ADDED VALIDATORS", control.validators)
          // break;
        default:
          this.myForm.addControl(
            control.name || '',
            this.fb.control(
              { value: control.value, disabled: control.disabled },
              Validators.compose([...control.validators])
            )
          );
          break;
      }
    }
    // console.log(this.myForm);
  }
  save() {
    this.outputData.emit(this.myForm.value);
  }

  chipClick(any?: any) {}

  public errorWrap(errors: any, item: any): string {
    let s = '';
    if (errors) {
      const k = Object.keys(errors) || [];
      // fix lower.
      item?.forEach((element) => {
        if (element.type === 'regex') {
          if (k?.indexOf('pattern') !== -1) {
            s += element.message;
          }
        } else {
          if (k?.indexOf(element?.type.toLowerCase()) !== -1) {
            s += element.message;
          }
        }
      });
    }
    return s;
  }
}
