<!-- TODO: ASSYNC LOADING WITH DATA ON FORM RESOLVES IN EMPTY -->


<div *ngIf="data.action.form" style="width: 100%;">
  <app-form-wrapper [(data)]="data" [wrapp]="true" *ngIf="data.action.form.type === '.AssetStructuredForm'" (disableDeactivate)="deactivate($event)">
  </app-form-wrapper>
  <app-form-wrapper [(data)]="data" [wrapp]="true" *ngIf="data.action.form.type === '.DisplayAssetStructuredForm'"(disableDeactivate)="deactivate($event)">
  </app-form-wrapper>
  <app-form-wrapper [(data)]="data" [wrapp]="true" *ngIf="data.action.form.type === '.DisplayWalletForm'" (disableDeactivate)="deactivate($event)">
  </app-form-wrapper>

  <app-json-form *ngIf="data.action.form.type === '.JSONBasedAssetDetailForm'" [data]="data"
    title="JSONBasedAssetDetailForm"></app-json-form>
  <app-json-form *ngIf="data.action.form.type === '.DisplayAssetListDetailsForm'" [data]="data"
    title="DisplayAssetListDetailsForm"></app-json-form>
  <app-json-list-form *ngIf="data.action.form.type === '.JSONBasedAssetListDetailForm'" [data]="data"
    title="JSONBasedAssetListDetailForm"></app-json-list-form>
</div>


<div *ngIf="data.action.type === '.DisplayTableForm'" class="fit">
  <app-table></app-table>
</div>

<div *ngIf="!data" class="wrapper">
  <img src="assets/images/logo.png" class="placeholder" style="max-height: 120px;">
</div>

<div class="container" *ngIf="data.action.type=== '.GenerateTableExport'">
  <span class="text-center"> Your download will start shortly</span>
</div>

<div *ngIf="data.action.type === '.frontend.DirectDownloadFile'">
  <ui-file-download></ui-file-download>
</div>
<div *ngIf="data.action.type === '.frontend.DelayedDownloadFile'">
  <ui-delayed-download></ui-delayed-download>
</div>

<div *ngIf="data.action.type === '.frontend.ShowHLRBrowser'" class="fit">
  <ui-charon-browser></ui-charon-browser>
</div>

<div *ngIf="data.action.type === '.frontend.ShowHLRInstances'" class="fit">
  <ui-charon-instances-view></ui-charon-instances-view>
</div>



<div *ngIf="data.action.type === '.frontend.ShowCDRBrowser'" class="fit">
  <ui-cdr2></ui-cdr2>
</div>
<div *ngIf="data.action.type === '.frontend.ShowCDRExports'" class="fit">
  <!-- <ui-cdr>  </ui-cdr> -->
  <ui-export-browswer></ui-export-browswer>
</div>

<div *ngIf="data.action.type === '.frontend.ShowTicketBrowser'" class="fit">
  <!-- <ui-cdr>  </ui-cdr> -->
  <ui-ticket-browser></ui-ticket-browser>
</div>

<div *ngIf="data.action.type === '.frontend.ShowTicketSettings'" class="fit">
  settings
</div>

<div *ngIf="data.action.type === '.frontend.ShowTicketEngineStatus'" class="fit">
  engine status
</div>

<div *ngIf="data.action.type === '.frontend.ShowTicketTemplates'" class="fit">
  engine status
</div>
