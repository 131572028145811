import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import {
  DialogService,
  DialogsModule,
  WindowService,
} from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { EditorModule } from '@progress/kendo-angular-editor';
import { GridModule } from '@progress/kendo-angular-grid';
import { IconsModule } from '@progress/kendo-angular-icons';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { ContextMenuModule } from '@progress/kendo-angular-menu';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { PopupModule } from '@progress/kendo-angular-popup';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
// import { TimeagoModule } from 'ngx-timeago';
import { RouterModule } from '@angular/router';
import { LogicPlanComponent } from './logic-plan/logic-plan.component';
import { LogicPlanRuleFormComponent } from './logic-plan-rule-form/logic-plan-rule-form.component';
import { OrganisationSelectorComponent } from './organisation-selector/organisation-selector.component';
import { EditorComponent } from './editor/editor.component';

@NgModule({
  declarations: [
    LogicPlanComponent,
    LogicPlanRuleFormComponent,
    OrganisationSelectorComponent,
    EditorComponent
  ],
  exports: [
    LogicPlanComponent,
    LogicPlanRuleFormComponent,
    OrganisationSelectorComponent,
    EditorComponent
  ],
  imports: [
    CommonModule,
    LayoutModule,
    ButtonsModule,
    TreeViewModule,
    IndicatorsModule,
    PopupModule,
    InputsModule,
    IconsModule,
    NavigationModule,
    NgxJsonViewerModule,
    FormsModule,
    GridModule,
    ReactiveFormsModule,
    LabelModule,
    DateInputsModule,
    DropDownsModule,
    ProgressBarModule,
    DialogsModule,
    ContextMenuModule,
    ListViewModule,
    // TimeagoModule.forChild(),
    EditorModule,
    TreeListModule,
    RouterModule,
  ],
  providers: [DialogService, WindowService],
})
export class UiControlsModule {}
