import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { PopupModule } from '@progress/kendo-angular-popup';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { IconsModule } from '@progress/kendo-angular-icons';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpMockRequestInterceptor } from './interceptors/http-mock.interceptor';
import { HttpRequestInterceptor } from './interceptors/http.interceptor';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { ErrorPageComponent } from './status/error-page/error-page.component';

import { TopnavComponent } from './view/topnav/topnav.component';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { CrmModule } from './crm/crm.module';
import { GridModule } from '@progress/kendo-angular-grid';
import { LabelModule } from '@progress/kendo-angular-label';
import { DashboardModule } from './dashboard/dashboard.module';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { environment } from 'src/environments/environment.prod';

import { DialogsModule } from '@progress/kendo-angular-dialog';
import { MenuModule } from '@progress/kendo-angular-menu';
import { StatePersistingService } from './services/grid-state.service';
// import { TimeagoModule } from 'ngx-timeago';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { EditorModule } from '@progress/kendo-angular-editor';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { QueryParamService } from './services/param.service';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { ServiceWorkerModule } from '@angular/service-worker';











const config: any = environment.keycloak;


console.log('this is my env', (window as any).env);


function initializeKeycloak(keycloak: KeycloakService) {

  return () =>
    keycloak.init({
      config: environment.keycloak.config,
      initOptions: {
        onLoad: 'login-required',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html',
      },
      bearerExcludedUrls: ['/assets', 'https://s3.*'],

    });
}

export const isMock = true

@NgModule({
  declarations: [AppComponent, ErrorPageComponent, TopnavComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TreeViewModule,
    BrowserAnimationsModule,
    LayoutModule,
    IndicatorsModule,
    PopupModule,
    InputsModule,
    IconsModule,
    NavigationModule,
    ButtonsModule,
    KeycloakAngularModule,
    HttpClientModule,
    NgxJsonViewerModule,
    CrmModule,
    DashboardModule,
    GridModule,
    LabelModule,
    ChartsModule,
    NotificationModule,
    DialogsModule,
    MenuModule,
    // TimeagoModule.forRoot(),
    ListViewModule,
    EditorModule,
    TreeListModule,
    SortableModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: false,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })

  ],
  providers: [
    StatePersistingService,
    QueryParamService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: isMock ? HttpMockRequestInterceptor : HttpRequestInterceptor,
      multi: true
    },
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: true,
    //   }),
    // },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => {},
    //   deps: [Sentry.TraceService],
    //   multi: true,
    // },
    HttpClientModule
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
