import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-json-view',
  templateUrl: './json-view.component.html',
})
export class JsonViewComponent implements OnInit {

  @Input() data: any;
  @Input() divHeight = '100px';

  constructor() { }

  ngOnInit(): void {
  }

}
