import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Uri, editor, languages } from 'monaco-editor/esm/vs/editor/editor.api';

import 'monaco-editor';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subject, pipe, take } from 'rxjs';

@Component({
  selector: 'code-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: EditorComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.Default
})
export class EditorComponent
  implements OnInit, ControlValueAccessor, AfterViewInit
{
  @ViewChild('editorContainer', { static: true })
  _editorContainer!: ElementRef;
  codeEditorInstance!: editor.IStandaloneCodeEditor;

  @Input() code: any;
  @Input() disabled: Observable<boolean> = new Subject<boolean>();
  @Input() options: any;

  @Output("codeEditorReady") codeEditorReady =  new EventEmitter<boolean>();
  private ready = false;

  onChange = (value) => {
    this.markAsTouched();
  };

  onTouched = () => {};

  touched = false;

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private ref: ChangeDetectorRef) {}

  writeValue(obj: string): void {
    console.log('WRITE VALUE', obj);
    this.code = obj;
    if (!!this.codeEditorInstance?.getModel()) {
      console.log('Set value');
      this.codeEditorInstance.getModel().setValue(obj);
    }

  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = this.disabled;
  }

  ngAfterViewInit(): void {
    // this.LoadCodeEditor()
    console.log('after init');
    this.LoadCodeEditor();
    console.debug("editor instance", this.codeEditorInstance )

  }

  ngOnInit() {

    languages.register({ id: 'SLSL' });

    // Register a tokens provider for the language
    languages.setMonarchTokensProvider('SLSL', {
      tokenizer: {
        root: [
          [/ON/, 'keyword'],
          [/ERROR/, 'keyword'],
          [/RESUME/, 'keyword'],
          [/DEFAULT/, 'keyword'],
          [/BUT/, 'keyword'],
          [/Allow/, 'expression'],
          [/True/, 'true'],
          [/False/, 'false'],
          [/FOR/, { token: 'keyword', next: '@afterFor' }],
          [/WHEN/, { token: 'keyword', next: '@afterWhen' }],
          [/IS/, { token: 'keyword', next: '@afterIs' }],
          [/SET/, { token: 'keyword', next: '@afterSet' }],
          [/[a-zA-Z_]\w*\.[a-zA-Z_]\w*/, 'property'],
          [/,/, { token: 'delimiter', next: '@afterComma' }],
        ],
        afterFor: [
          [/[a-zA-Z_]\w*\.[a-zA-Z_]\w*/, { token: 'property' }],
          [/[a-zA-Z_]\w*/, { token: 'identifier', next: '@pop' }],
          [/,/, { token: 'delimiter', next: '@afterComma' }],
        ],
        afterWhen: [
          [/[a-zA-Z_]\w*\.[a-zA-Z_]\w*/, { token: 'property' }],
          [/[a-zA-Z_]\w*/, { token: 'identifier', next: '@pop' }],
          [/,/, { token: 'delimiter', next: '@afterComma' }],
        ],
        afterIs: [
          [/[a-zA-Z_]\w*\.[a-zA-Z_]\w*/, { token: 'property' }],
          [/[a-zA-Z_]\w*/, { token: 'identifier', next: '@pop' }],
          [/,/, { token: 'delimiter', next: '@afterComma' }],
        ],
        afterSet: [
          [/[a-zA-Z_]\w*\.[a-zA-Z_]\w*/, { token: 'property' }],
          [/[a-zA-Z_]\w*/, { token: 'identifier2', next: '@pop' }],
          [/,/, { token: 'delimiter', next: '@afterComma' }],
        ],
        afterComma: [
          [/[a-zA-Z_]\w*\.[a-zA-Z_]\w*/, { token: 'property' }],
          [/[a-zA-Z_]\w*/, { token: 'identifier2', next: '@pop' }],
        ],
      },
    });

    editor.defineTheme('SlslTheme', {
      base: 'vs',
      inherit: false,
      rules: [
        { token: 'keyword', foreground: '#0000FF' },
        { token: 'property', foreground: '#d95555' },
        { token: 'true', foreground: '#3ead4a' },
        { token: 'false', foreground: '#ad3e3e' },
        { token: 'expression', foreground: '#3ead4a' },
        { token: 'identifier', foreground: '#3ead4a' },
        { token: 'identifier2', foreground: '#9b34eb' },
      ],
      colors: {
        'editor.foreground': '#000000',
      },
    });

    languages.setLanguageConfiguration('SLSL', {
      // indentationRules: {
      //   increaseIndentPattern: RegExp(''),
      //   decreaseIndentPattern: RegExp('')
      // },

      onEnterRules: [
        {
          beforeText: /^\s*|ON|IF|RESUME|DEFAULT|FOR\b/,
          action: {
            indentAction: languages.IndentAction.IndentOutdent,
          },
        },
      ],
      comments: {
        lineComment: '-->',
      },
    });

    languages.registerCompletionItemProvider('SLSL', {
      provideCompletionItems: (model, position) => {
        const word = model.getWordUntilPosition(position);
        const range = {
          startLineNumber: position.lineNumber,
          endLineNumber: position.lineNumber,
          startColumn: word.startColumn,
          endColumn: word.endColumn,
        };

        const suggestions = [
          {
            label: 'ON',
            kind: languages.CompletionItemKind.Text,
            insertText: 'ON',
            range: range,
          },
          {
            label: 'ERROR',
            kind: languages.CompletionItemKind.Text,
            insertText: 'ERROR',
            range: range,
          },
          {
            label: 'RESUME',
            kind: languages.CompletionItemKind.Text,
            insertText: 'RESUME',
            range: range,
          },
          {
            label: 'DEFAULT',
            kind: languages.CompletionItemKind.Text,
            insertText: 'DEFAULT',
            range: range,
          },
          {
            label: 'FOR',
            kind: languages.CompletionItemKind.Text,
            insertText: 'FOR',
            range: range,
          },
          {
            label: 'WHEN',
            kind: languages.CompletionItemKind.Text,
            insertText: 'WHEN',
            range: range,
          },
          {
            label: 'BUT',
            kind: languages.CompletionItemKind.Text,
            insertText: 'BUT',
            range: range,
          },
          {
            label: 'SET',
            kind: languages.CompletionItemKind.Text,
            insertText: 'SET',
            range: range,
          },
          {
            label: 'fwhen',
            kind: languages.CompletionItemKind.Snippet,
            insertText: [
              'FOR ${1:X}',
              'WHEN ${2:X} IS ${3:X} : SET ${4:X} = ${5:X}',
            ].join('\n'),
            insertTextRules:
              languages.CompletionItemInsertTextRule.InsertAsSnippet,
            documentation: 'Inserts a conditional statement snippet',
            range: range,
          },
          {
            label: 'when',
            kind: languages.CompletionItemKind.Snippet,
            insertText: ['WHEN ${2:X} IS ${3:X} : SET ?'].join('\n'),
            insertTextRules:
              languages.CompletionItemInsertTextRule.InsertAsSnippet,
            documentation: 'Inserts a conditional statement snippet',
            range: range,
          },
        ];
        return { suggestions: suggestions };
      },
    });

    this.LoadCodeEditor();

    console.log('on init');
  }

  ClearCodeEditor() {
    if (!!this.codeEditorInstance) {
      this.codeEditorInstance.dispose();
    }
  }

  GetTheme(): string {
    switch (this.options?.language) {
      case 'SLSL':
        return 'SlslTheme';
      default:
        return 'JSON';
    }
  }

  LoadCodeEditor() {

    this.disabled.subscribe({
      next: (dis) => {
        this.ClearCodeEditor();

        var language = this.options?.language || 'SLSL';
        console.debug("CREATE EDITOR WITH", this.code)
        this.codeEditorInstance = editor.create(
          this._editorContainer?.nativeElement,
          {
            theme: this.GetTheme(),
            wordWrap: 'on',
            wrappingIndent: 'indent',
            minimap: { enabled: true },
            language: language,
            model: editor.createModel(this.code, language),
            tabSize: 4,
            fontSize: 16,
            readOnly: !dis,
          }
        );

        this.codeEditorInstance.onDidChangeModelContent((e) => {
          console.log('event', e);
          const model = this.codeEditorInstance.getModel();
          const text = model?.getValue();

          let newText = text?.replaceAll(/\bon\b/gi, 'ON') || '';
          newText = newText.replaceAll(/\berror\b/gi, 'ERROR');
          newText = newText.replaceAll(/\bresume\b/gi, 'RESUME');
          newText = newText.replaceAll(/\bdefault\b/gi, 'DEFAULT');
          newText = newText.replaceAll(/\bbut\b/gi, 'BUT');
          newText = newText.replaceAll(/\ballow\b/gi, 'Allow');
          newText = newText.replaceAll(/\btrue\b/gi, 'True');
          newText = newText.replaceAll(/\bfalse\b/gi, 'False');
          newText = newText.replaceAll(/\bfor\b/gi, 'FOR');
          newText = newText.replaceAll(/\bwhen\b/gi, 'WHEN');
          newText = newText.replaceAll(/\bis\b/gi, 'IS');
          newText = newText.replaceAll(/\bset\b/gi, 'SET');

          if (newText !== text) {
            // Use the pushEditOperations method to change the text without triggering
            // a new 'onDidChangeModelContent' event, which would cause an infinite loop.
            model?.pushEditOperations(
              [],
              [{ range: model.getFullModelRange(), text: newText }],
              function (inverseOp) {
                return null;
              }
            );
          }
          this.onChange(newText);
          console.log(newText);
          // this.codeEditorInstance.setValue(text);
        });

        const model = this.codeEditorInstance.getModel()
        console.debug("MODEL", model.getValue())

        if (!this.ready) {
          this.ready = true
          this.codeEditorReady.emit(true)
        }
      },
    });
  }
}
