import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetApiService } from 'src/app/services/asset-api.service';
import { AssetStructure } from 'src/app/shared/models/assets';
import { JsonFormControls } from '../../templates/form/form.component';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  public data: any;
  public id = '';
  public templateName = '';

  public template: any;
  constructor(private ar: ActivatedRoute, private api: AssetApiService, private router: Router) {
      // this.data = this.ar.snapshot.data;
      // console.log("DATA",this.ar.snapshot.data)

      this.ar.paramMap.subscribe((data) => {
        this.id = data.get('id') || '';
        this.template = data.get('template') || 'abacus.test.v1';
      });

   }

  ngOnInit(): void {

  }

  onSave(any: any){
    this.constTructAssetFromFlat(any);
  }

  constTructAssetFromFlat(any: any){
    const formMap = new Map(Object.entries(any));

    const asset: AssetStructure = new AssetStructure();
    asset.name = any.name;
    asset.parentUUID = any.parentUUID || '';
    asset.tags = any.tags || null;

    const dataMap = new Map<string, any>();
    formMap.forEach((value, key) => {
      if (key.startsWith('data/')){
        dataMap.set(key, value);
      }
    });

    const jsonObject = Object.fromEntries(dataMap);
    asset.data = jsonObject;

    asset.parentUUID = this.id;
    asset.template = this.template;
  }

  templateToForm(fields: any): void {
    const formField: Array<JsonFormControls> = new Array<JsonFormControls>();

    Object.entries(fields).forEach((field: any) => {
      const x = this.typeSelector(field[1]);
      x.name = field[0];
      x.label = field[0];
      formField.push(x);
    });
    this.data = {
      action: {
        form: {
          layout: [...formField]
        }
      }
    };
  }

  typeSelector(data: any): JsonFormControls {
    const x = new JsonFormControls();
    switch (data.type) {
      case '.AssetMapDefinition':
        x.type = 'text';
        break;

      default:
        break;
    }
    x.disabled = false;
    x.value = data.defaultValue || null;
    return x;
  }

}

