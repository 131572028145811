import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionService } from 'src/app/services/action-service';
import { Observable, Subscription } from 'rxjs';
import { consoleSandbox } from '@sentry/utils';
@Component({
  selector: 'ui-delayed-download',
  templateUrl: './delayed-download.component.html',
})
export class DelayedDownloadComponent implements OnInit, OnDestroy {

  public loading = true;
  public data: any;

  public downloadUrl = '';
  public pollingInterval = 0;

  public onWaitButtons: Array<any> = [];
  public $downloadSub: Subscription = new Subscription;
  public poll = true;

  constructor(private http: HttpClient, private actionService: ActionService, private router: Router, private ar: ActivatedRoute) {
    this.data = this.ar.snapshot.data.data || undefined;
    console.log('TRACE', this.data.action.url);
    this.downloadUrl = this.data.action.url;
    this.pollingInterval = this.data.action.onWait['polling-interval'] || 1000;
    this.onWaitButtons = this.data.action.onWait.actionButtons;
   }
  ngOnDestroy(): void {
    this.$downloadSub.unsubscribe();
    this.poll = false;
  }

  ngOnInit(): void {
    this.startFetching();

  }

  actionButtonClick(any: any) {
    any.actions?.forEach((element: any) => {
      if (element.type === '.frontend.CancelDownloadFilePolling') {
        this.poll = false;
        this.loading = false;
      } else {
        this.actionService.handleCallbackAction(element);
      }
    });
  }

  getUrl(): Observable<HttpResponse<any>> {
    console.log('GET URL');
    return this.http.get(this.downloadUrl, {observe: 'response'});
  }
  Next() {
    if (this.poll) {
      this.startFetching();
    }
  }

  startFetching() {
    this.$downloadSub = this.getUrl().subscribe(resp => {
      console.log('TRACE', resp);
      this.loading = false;
      this.download(this.downloadUrl);
      this.actionService.handleCallbackAction(this.data.action.onReady);
    }, error => {
      console.log('ER', error);
      if (error.status !== 200) {
        console.log('retry');
        setTimeout(() => {
          this.Next();
          return;
        }, this.pollingInterval);
      }
      if (error.status === 200){
        console.log('DO', error.url);
        this.loading = false;
        this.download(error.url);
        this.actionService.handleCallbackAction(this.data.action.onReady);
      }
    }, () => {
      console.log('done');
    });
  }

  download(url: string) {
    console.log('Start Download', url);
    this.http.get(url, {
      responseType: 'blob'
    }).subscribe(blob => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      // a.download = this.data.action.name;
      a.click();
      URL.revokeObjectURL(objectUrl);
    });
  }


}
