import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketDetailsComponent } from './ticket-details/ticket-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import {
  DialogService,
  DialogsModule,
  WindowService,
} from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { EditorModule } from '@progress/kendo-angular-editor';
import { GridModule } from '@progress/kendo-angular-grid';
import { IconsModule } from '@progress/kendo-angular-icons';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { ContextMenuModule } from '@progress/kendo-angular-menu';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { PopupModule } from '@progress/kendo-angular-popup';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
// import { TimeagoModule } from 'ngx-timeago';
import { TicketActionsComponent } from './ticket-actions/ticket-actions.component';
import { MessageComponent } from './message/message.component';
import { ContactComponent } from './contact/contact.component';
import { RouterModule } from '@angular/router';
import { UserFormBrowserComponent } from './user-form-browser/user.component';
import { UserFormComponent } from './user-form/user-form.component';
import { AssetBrowserComponent } from './asset-browser/asset-browser.component';
import { SupportLevelFormComponent } from './support-level-form/support-level-form.component';
import { SupportTicketStatusFormComponent } from './support-ticket-status-form/form.component';
import { SupportTicketTypeFormComponent } from './support-ticket-type-form/form.component';
import { TicketFormComponent } from './ticket-form/form.component';
import { MetaViewComponent } from './meta-view/meta-view.component';
import { EmailConfigFormComponent } from './email-config-form/form.component';
import { ModalService } from '../services/modal.service';
import { AttachmentPreviewComponent } from './preview/preview.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { DndDirective } from './message/dnd.directeve';
import { OrganisationFormComponent } from './organisation-form/form.component';
import { OrganisationSettingsFormComponent } from './organisation-settings-form/form.component';
import { OrganisationAssignComponent } from './organisation-assign/organisation-assign.component';
import { EscalationRuleFormComponent } from './escalation-rule-form/form.component';
import { UiControlsModule } from '../ui-controls/ui-controls.module';

@NgModule({
  declarations: [
    TicketDetailsComponent,
    TicketActionsComponent,
    MessageComponent,
    ContactComponent,
    UserFormBrowserComponent,
    UserFormComponent,
    AssetBrowserComponent,
    SupportLevelFormComponent,
    SupportTicketStatusFormComponent,
    SupportTicketTypeFormComponent,
    TicketFormComponent,
    MetaViewComponent,
    EmailConfigFormComponent,
    AttachmentPreviewComponent,
    DndDirective,
    OrganisationFormComponent,
    OrganisationSettingsFormComponent,
    OrganisationAssignComponent,
    EscalationRuleFormComponent
  ],
  exports: [
    TicketDetailsComponent,
    TicketActionsComponent,
    MessageComponent,
    ContactComponent,
    UserFormBrowserComponent,
    UserFormComponent,
    AssetBrowserComponent,
    SupportLevelFormComponent,
    SupportTicketStatusFormComponent,
    SupportTicketTypeFormComponent,
    TicketFormComponent,
    MetaViewComponent,
    EmailConfigFormComponent,
    AttachmentPreviewComponent,
    OrganisationFormComponent,
    OrganisationSettingsFormComponent,
    OrganisationAssignComponent,
    EscalationRuleFormComponent
  ],
  imports: [
    CommonModule,
    LayoutModule,
    ButtonsModule,
    TreeViewModule,
    IndicatorsModule,
    PopupModule,
    InputsModule,
    IconsModule,
    NavigationModule,
    NgxJsonViewerModule,
    FormsModule,
    GridModule,
    ReactiveFormsModule,
    LabelModule,
    DateInputsModule,
    DropDownsModule,
    ProgressBarModule,
    DialogsModule,
    ContextMenuModule,
    ListViewModule,
    // TimeagoModule.forChild(),
    EditorModule,
    TreeListModule,
    RouterModule,
    NgxExtendedPdfViewerModule,
    UiControlsModule
  ],
  providers: [DialogService, WindowService, ModalService],
})
export class UiModule {}
