import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, Observer, Subscription } from 'rxjs';
import { MenuItem } from 'src/app/models/menu';
import { ActionService } from 'src/app/services/action-service';
import { AssetApiService } from 'src/app/services/asset-api.service';
import { ContextMenuComponent } from '@progress/kendo-angular-menu';

@Component({
  selector: 'app-treeview',
  templateUrl: './treeview.component.html',
  // styleUrls: ['./treeview.component.css'],
})
export class TreeviewComponent implements OnInit, OnDestroy {
  constructor(
    private api: AssetApiService,
    private router: Router,
    private ar: ActivatedRoute,
    private actionService: ActionService
  ) {
    this.routerSub = router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.id = this.ar.firstChild?.snapshot.paramMap.get('id') || '';
      }
    });

    this.actionService.refreshMainMenuAction$.subscribe((val) => {
      this.fetchRootItems();
    });
  }
  public categories: Observable<any[]> = new Observable();
  public title: any;
  public id = '';

  public datax: MenuItem[] = new Array<MenuItem>();

  public keys: string[] = [];
  public search = '';

  public expandedKeys: any[] = [''];

  public selectedItem: any = null;

  public routerSub: Subscription;

  @ViewChild('treemenu')
  public treeContextMenu?: ContextMenuComponent;
  private contextItem?: any;

  public items: any[] = [{ text: 'Remove', icon: 'close' }];

  public selectedKeys: any[] = [];
  ngOnDestroy(): void {
    this.routerSub.unsubscribe();
  }
  canDeactivate(): Observable<boolean> | boolean {
    return true;
  }

  async ngOnInit(): Promise<void> {
    this.expandedKeys = JSON.parse(localStorage.getItem('treeState') || '[]');

    this.fetchRootItems();
    this.api
      .getInfo()
      .toPromise()
      .then((data) => {
        this.title = data;
      });
  }

  public isExpanded = (dataItem: any, index: string) => {
    const x = this.expandedKeys.indexOf(index) > -1;
    return this.expandedKeys.indexOf(index) > -1;
  };
  public handleCollapse(node: any) {
    this.expandedKeys = this.expandedKeys.filter((k) => k !== node.index);
    localStorage.setItem('treeState', JSON.stringify(this.expandedKeys));
  }
  public handleExpand(node: any) {
    this.expandedKeys = this.expandedKeys.concat(node.index);
    localStorage.setItem('treeState', JSON.stringify(this.expandedKeys));
  }

  async fetchRootItems() {
    this.api
      .getMainMenuRootElements()
      .toPromise()
      .then((data) => {
        this.datax = data;
      });
  }
  public fetchChildren(node: any): Observable<MenuItem[]> {
    return this.api.getMainMenuSubItems(node.item.dataItem.uuid);
  }
  public hasChildren(node: any): boolean {
    // Check if the parent node has children.
    return true;
  }
  //
  public onNodeClick(e: any): void {
    console.log(e);
    const uuid = e.item.dataItem.uuid;
    console.log(uuid);
    this.api
      .getMainMenuClick(e.item.dataItem.uuid)
      .toPromise()
      .then((data) => {
        this.router.navigate([
          `crm/${e.item.dataItem.uuid}/action/${data.action.name}`,
        ]);
      });
    if (e.type === 'contextmenu') {
      this.api
        .getContextMenu(uuid)
        .toPromise()
        .then((data) => {
          // TODO: make list.
          console.log(data);
          this.items = data.items;
        })
        .catch((error) => {
          this.items = [];
        });
      const originalEvent = e.originalEvent;

      originalEvent.preventDefault();

      this.contextItem = e.item.dataItem;

      this.treeContextMenu?.show({
        left: originalEvent.pageX,
        top: originalEvent.pageY,
      });
    }
  }
  public onSelect({ item }: any): void {
    console.log('SELECT', item);
    if (Array.isArray(item.action)) {
      item.action?.forEach((act: any) => {
        this.actionService.handleCallbackAction(act);
      });
    } else {
      this.actionService.handleCallbackAction(item.action);
    }
  }

  CreateForm() {
    this.router.navigate([`crm/create/asset`, this.selectedItem.uuid]);
  }

  public isItemSelected = (_: any, index: string) =>
    this.selectedKeys.indexOf(index) > -1;

  public handleSelection({ index, dataItem }: any): void {
    this.selectedKeys = [index];
    this.selectedItem = dataItem;
  }
}
